<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí chuyên mục tải tài liệu
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogCategoryMaterial"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm chuyên mục</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp các chuyên mục</span>
      </v-btn>

      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tên chuyên mục</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(category_material, i) in category_materials"
                  >
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ category_material.name }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="category_material.avatar"
                          :src="category_material.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editCategoryMaterial(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'MaterialList',
                                  params: { id: category_material.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-text-box-multiple</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Danh sách tài liệu</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  deleteCategoryMaterial(category_material.id)
                                "
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!category_materials.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="1000px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled cursor-pointer"
                      v-for="(item, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1">
                          <span style="color: #3699ff">{{ item.name }}</span>
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="sortCategoryMaterial"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <CategoryForm
      :show_dialog="dialogCategoryMaterial"
      :type_form="type_form"
      :offset="offset_input"
      :category_material="category_material"
      :skills="skill_tags"
      @setDialog="(e) => (this.dialogCategoryMaterial = e)"
      @resetData="getAllCategoryMaterial()"
    ></CategoryForm>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import CategoryForm from "@/view/pages/material/category/FormModal";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
export default {
  name: "CategoryMaterialList",
  components: {
    draggable,
    CategoryForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_materials: [],
      category_material: {},
      dialogCategoryMaterial: false,
      offset_input: null,
      type_form: null,
      skill_tags: [],
      dialogSort: false,
      data_draggable: [],
    };
  },
  created() {
    this.getAllCategoryMaterial();
    this.getAllSkill();
  },
  methods: {
    async getAllSkill() {
      let vm = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          vm.skill_tags = response.data;
        }
      });
    },
    async getAllCategoryMaterial() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/category-material");
        console.log(res);
        if (res.status === 200) {
          vm.category_materials = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogCategoryMaterial() {
      this.dialogCategoryMaterial = true;
      this.offset_input = this.category_materials.length + 1;
      this.type_form = "add";
    },
    editCategoryMaterial(index) {
      this.dialogCategoryMaterial = true;
      this.category_material = this.category_materials[index];
      this.type_form = "update";
    },
    deleteCategoryMaterial(category_material_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/category-material/" + category_material_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllCategoryMaterial();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.category_materials));
      this.dialogSort = true;
    },
    sortCategoryMaterial() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              category_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/category-material/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllCategoryMaterial();
                vm.$toasted.success("Sắp xếp thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
